import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';

import { map } from 'rxjs';

import { injectWebAppRoutes } from 'projects/web/src/app/features/shared/web-route-paths';

import { AppPermissionService } from '../services/permission-services/app-permission.service';

/** Denies access to the Field App to users without the necessary permissions. */
export function fieldAppGuard(): CanMatchFn {
	return () => {
		const appPermissionService = inject(AppPermissionService);
		const router = inject(Router);
		const routePath = injectWebAppRoutes();

		return appPermissionService.canAccessFieldApp$().pipe(
			map(canAccessFieldApp => canAccessFieldApp ? true : router.parseUrl(routePath.backOffice.path)),
		);
	};
}
